import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox, ItemContainer } from "@/game-fortnite/components/ItemBox";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";

const COLS = [
  {
    i18n: ["common:weapon", "Weapon"],
    dataKey: "weapon",
  },
  {
    i18n: ["fortnite:stats.dps", "DPS"],
    dataKey: "dps",
  },
  {
    i18n: ["fortnite:stats.damage", "Damage"],
    dataKey: "damage",
  },
  {
    i18n: ["fortnite.stats.magSize", "Mag. Size"],
    dataKey: "magazineSize",
  },
];

interface Weapon {
  id: string;
  imageKey: string;
  name: string;
  rarity: Rarity;
  category: string;
  dps: number;
  damage: number;
  magazineSize: number;
  link: string;
}

export default function HomeStatisitcsTile() {
  const { t } = useTranslation();

  const unfilteredWeapons = useWeaponsFromItems();
  const weapons: Record<string, Weapon> = useMemo(() => {
    const activeWeapons = Object.values(unfilteredWeapons).filter(
      (item) => item.isActive && Object.keys(item.stats || {}).length !== 0,
    );
    return activeWeapons.reduce((acc, w) => {
      acc[w.id.toLowerCase()] = {
        id: w.id,
        imageKey: w.icon,
        name: w.displayName,
        rarity: w.rarity,
        category: w.category,
        dps:
          w.stats.damagePerBullet *
          (w.stats.firingRate || 1) *
          (w.stats.bulletsPerCartridge || 1),
        damage: w.stats.damagePerBullet,
        magazineSize: w.stats.clipSize,
        link: `/fortnite/database/weapon/${getWeaponKey(w.displayName)}`,
      };
      return acc;
    }, {});
  }, [unfilteredWeapons]);

  const data = useMemo(() => {
    // only keep one weapon per category and rarity
    return Object.values(weapons)
      .sort((a, b) => b?.dps - a?.dps)
      .reduce((acc, w) => {
        if (acc.some((a) => a.category === w.category)) return acc;
        if (acc.some((a) => a.rarity === w.rarity)) return acc;
        return [...acc, w];
      }, []);
  }, [weapons]);

  return (
    <GameGuideTileLg
      title={["common:navigation.database", "Database"]}
      description={[
        "home:guides.fortnite.database.description",
        "Discover which Weapons perform best with their up-to-date stats like DPS, damage and other helpful statistics.",
      ]}
      buttonText={["common:navigation.viewWeapons", "View Weapons"]}
      href={"/fortnite/database/weapons/active"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="weapon-meta">{t(COLS[0].i18n)}</div>
          <div className="stat">{t(COLS[1].i18n)}</div>
          <div className="stat">{t(COLS[2].i18n)}</div>
          <div className="stat">{t(COLS[3].i18n)}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body numRows={5}>
          {(data || []).map((row, i) => (
            <GameGuideTable.Row key={i} link={row.link}>
              <div className={`weapon-meta type-subtitle--bold`}>
                <ItemContainer>
                  <ItemBox
                    id={row.id}
                    name={row.name}
                    rarity={row.rarity.toUpperCase() as Rarity}
                    src={`${appURLs.CDN}/fortnite/images/${row.imageKey}.webp`}
                  />
                  <p className="type-callout--bold shade0 name">{row.name}</p>
                </ItemContainer>
              </div>
              <div className="stat type-body2-form--bold">
                {row.dps?.toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 1,
                }) ?? "-"}
              </div>
              <span className="stat type-body2-form--bold">
                {row.damage?.toLocaleString(getLocale()) ?? "-"}
              </span>
              <span className="stat type-body2-form--bold">
                {row.magazineSize?.toLocaleString(getLocale()) ?? "-"}
              </span>
            </GameGuideTable.Row>
          ))}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .weapon-meta {
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
  }
  .weapon-img {
    width: 4.5rem;
    height: auto;
    aspect-ratio: 350 / 197;
    border-radius: var(--br-sm);
  }
  .weapon-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;
